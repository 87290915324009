import React from "react";
import { Router } from "@reach/router";
import Loadable from '@loadable/component';

import Footer from "../components/Footer";
import Header from "../components/Header";

import GlobalStyles from "../globalStyles";

const Home = Loadable(() => import(`../components/Home`));
const Novaz = Loadable(() => import(`../components/Home/novaz`))
const Reda = Loadable(() => import(`../components/Home/reda`))

const MainRouter = () => {
  return (
    <>
      <GlobalStyles />
      <Header />
        <Router>
          <Home path="/" />
          {/* <Novaz path="/" /> */}
          <Novaz path="/novaz" />
          <Reda path="/reda" />
          {/* <Reda path="/" /> */}
        </Router>
      {/* <Footer /> */}
    </>
  );
};

export default MainRouter;
